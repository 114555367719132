<template>
    <Header/>
    <Careers/>
    <Footer/>
    
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Careers from '@/components/CareersFresher.vue'


export default {
  components: {
    Header,
    Footer,
    Careers

  }
}
</script>
