<template>
     <section id="Careers" class="careers section-bg">
      <div class="container">
        <div class="section-title">
          <h2>CAREERS</h2>
          <p>What We Do With Love</p>
           <div class="freshersection mt-4">
               <h4>Freshers
                  <span>
                    <button type="button" class="get-started-btn" data-bs-toggle="modal" data-bs-target="#markucifresher">APPLY NOW</button>
                  </span>
                </h4>
             </div>
          </div>

                 <div class="row careerform">
            <Carousel :items-to-show="3" :autoplay="3000" :transition="1000" :itemsToScroll="1" :wrap-around="true" class="desktopfresher">
                  <Slide  v-for="slide in slider" :key="slide.id">
                    <div class="carousel__item">
                      <div class="col-md-12" >
                            <div class="boxcareer" >
                            <h3 class="Careerheading">{{slide.header}}</h3>
                            <ul>
                              <li><b>Experience</b> : Min 2 years</li>
                              <li><b>Job Location</b> : Chennai</li>
                             <button type="button" class="get-started-btn" data-bs-toggle="modal" data-bs-target="#markuciexperience">APPLY NOW</button>
                            </ul>
                          </div>
                        </div>
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation  class="navpage" />
                    <Pagination/>
                  </template>
                </Carousel>
                   </div>

                   <div class="row">
                      <div class="col-md-12 mobilefresher" >
                            <div class="boxcareer" v-for="slide in slider" :key="slide.id" >
                            <h3 class="Careerheading">{{slide.header}}</h3>
                            <ul>
                              <li><b>Experience</b> : Min 2 years</li>
                              <li><b>Job Location</b> : Chennai</li>
                             <button type="button" class="get-started-btn" data-bs-toggle="modal" data-bs-target="#markuciexperience">APPLY NOW</button>
                            </ul>
                          </div>
                        </div>
                    </div>
     
        <!-- Modal Freshers -->
        <div class="modal fade" id="markucifresher" tabindex="-1" aria-labelledby="markucifresherLabel" aria-hidden="true">
                     <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="btn-close d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>

                              <div class="row no-gutters">
                                <div class="col-md-6 d-flex">
                                  <div class="modal-body p-5 leftmodal d-flex">
                                    <div class="text w-100 popuphead">
                                      <h2>CAREERS FORM</h2>
                                      <p>What We Do With Love</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 d-flex p-5">
                              <div class="modal-body">
                                   <div id="Markucicareerform" >
                                      <form enctype="multipart/form-data" method="post" @submit.prevent="sendEmailFreshers"  ref="form">
                                            <div class="form-data">
                                                <div class="forms-inputs mb-4"> <span  class="form-label">Fullname</span>
                                                <input type="text" name="usernamefreshers" id="usernamefreshers"  v-model="usernamefreshers" v-bind:class="{'form-control':true, 'is-invalid' : !validUsernamefreshers(usernamefreshers) && usernamefreshersBlured}" v-on:blur="usernamefreshersBlured = true" placeholder="Fullname">
                                                    <div class="invalid-feedback">A valid fullname is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span class="form-label">Email</span>
                                                <input type="text" id="emailfreshers" name="emailfreshers" v-model="emailfreshers" v-bind:class="{'form-control':true, 'is-invalid' : !validEmailfreshers(emailfreshers) && emailfreshersBlured}" v-on:blur="emailfreshersBlured = true" placeholder="Email Address">
                                                    <div class="invalid-feedback">A valid email is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span class="form-label">Phone Number</span>
                                                <input type="text" name="phonefreshers" id="phonefreshers" v-model="phonefreshers" v-bind:class="{'form-control':true, 'is-invalid' : !validPhonefreshers(phonefreshers) && phonefreshersBlured}" v-on:blur="phonefreshersBlured = true" placeholder="Phone Number">
                                                    <div class="invalid-feedback">A valid phone is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span  class="form-label">Current Location</span>
                                                <input type="text" name="location" id="location" v-bind:class="{'form-control':true}" placeholder="Location">
                                                </div>
                                                 <div class="forms-inputs mb-4"> <span class="form-label">Education</span> 
                                                       <select v-model="data.education" name="Education" id="Education" v-bind:class="{'form-select':true}">
                                                        <option value="" disabled>Select Your Education</option>
                                                        <option
                                                            v-for="(education, i) in educations"
                                                            :value="education['code']"
                                                            :key="i"
                                                            >{{ education['name'] }}</option>
                                                    </select>
                                                      </div>
                                                       <div class="forms-inputs mb-4"> <span class="form-label">Year Of Passed Out</span> 
                                                        <select v-model="data.passedout" name="Passedout" id="Passedout" v-bind:class="{'form-select':true}">
                                                            <option value="" disabled>Select Your Year</option>
                                                            <option
                                                                v-for="(passedout, i) in passedoutdata"
                                                                :value="passedout['code']"
                                                                :key="i"
                                                                >{{ passedout['name'] }}</option>
                                                        </select>
                                                        </div>
                                                      <div class="forms-inputs mb-4"> <span class="form-label">Certificates (Optional)</span> 
                                                        <select v-model="data.certificates" name="certificates" id="certificates" v-bind:class="{'form-select':true}">
                                                            <option value="" disabled>Select Your Option</option>
                                                            <option
                                                                v-for="(certificates, i) in certificatesdata"
                                                                :value="certificates['code']"
                                                                :key="i"
                                                                >{{ certificates['name'] }}</option>
                                                        </select>
                                                    </div>
                                                      <div class="forms-inputs mb-4"> <span class="form-label">Remark</span> 
                                                      <textarea name="coverletter" id="coverletter" v-bind:class="{'form-control':true}" rows="3" placeholder="Remark"></textarea>
                                                      </div>
                                                      <div class="mb-3">
                                                        <button type="submit" id="buttonFreshers" value="Send Email" class="popupsubmit w-100">Apply Now</button>
                                                          </div>
                                                  </div>
                                            </form>
                                       <div class="alert alert-success" v-if="Successmessage">
                                                <div class="text-center d-flex flex-column"> <i class="lni lni-smile formsuccess"></i> <span class="text-center fs-2">Thank you!</span> <span class="text-center fs-4">Your Submission has been sent.</span> </div>
                                          </div>
                                  </div>

                              </div>
                            </div>
                            </div>
                     </div>
                </div>
         </div>

         <!-- Modal Experience -->
        <div class="modal fade" id="markuciexperience" tabindex="-1" aria-labelledby="markuciexperienceLabel" aria-hidden="true">
                     <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="btn-close d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>

                              <div class="row no-gutters">
                                <div class="col-md-6 d-flex">
                                  <div class="modal-body p-5 leftmodal d-flex">
                                    <div class="text w-100 popuphead">
                                      <h2>CAREERS FORM</h2>
                                      <p>What We Do With Love</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 d-flex p-5">
                              <div class="modal-body">
                                   <div id="Markucicareerform" >
                                      <form enctype="multipart/form-data" method="post" @submit.prevent="sendEmailExperience"  ref="form">
                                            <div class="form-data">
                                                <div class="forms-inputs mb-4"> <span  class="form-label">Fullname</span>
                                                <input type="text" name="username" id="username"  v-model="username" v-bind:class="{'form-control':true, 'is-invalid' : !validUsername(username) && usernameBlured}" v-on:blur="usernameBlured = true" placeholder="Fullname">
                                                    <div class="invalid-feedback">A valid fullname is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span class="form-label">Email</span>
                                                <input type="text" id="email" name="email" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true" placeholder="Email Address">
                                                    <div class="invalid-feedback">A valid email is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span class="form-label">Phone Number</span>
                                                <input type="text" name="phone" id="phone" v-model="phone" v-bind:class="{'form-control':true, 'is-invalid' : !validPhone(phone) && phoneBlured}" v-on:blur="phoneBlured = true" placeholder="Phone Number">
                                                    <div class="invalid-feedback">A valid phone is required!</div>
                                                </div>
                                                <div class="forms-inputs mb-4"> <span  class="form-label">Current Location</span>
                                                <input type="text" name="location" id="location" v-bind:class="{'form-control':true}" placeholder="Location">
                                                </div>
                                                 <div class="forms-inputs mb-4"> <span class="form-label">Job Title</span> 
                                                      <select v-model="data.education"  name="JobTitle" id="JobTitle" v-bind:class="{'form-select':true}">
                                                          <option value="" disabled>Select Your Job Title</option>
                                                          <option
                                                              v-for="(MoreExperience, i) in MoreExperiences"
                                                              :value="MoreExperience['code']"
                                                              :key="i"
                                                              >{{ MoreExperience['name'] }}</option >
                                                      </select>
                                                      </div>      
                                                <div class="forms-inputs mb-4"> <span class="form-label">Upload Resume ( Max upload size is 500 kb )</span>
                                                <input type="file" name="my_file" id="my_file" v-bind:class="{'form-control':true}" >
                                                </div>
                                                      <div class="forms-inputs mb-4"> <span class="form-label">Remark</span> 
                                                      <textarea name="coverletter" id="coverletter" v-bind:class="{'form-control':true}" rows="3" placeholder="Remark"></textarea>
                                                      </div>
                                                      <div class="mb-3">
                                                        <button type="submit" id="buttonExperience" value="Send Email" class="popupsubmit w-100">Apply Now</button>
                                                          </div>
                                                  </div>
                                            </form>
                                       <div class="alert alert-success" v-if="Successmessage">
                                                <div class="text-center d-flex flex-column"> <i class="lni lni-smile formsuccess"></i> <span class="text-center fs-2">Thank you!</span> <span class="text-center fs-4">Your Submission has been sent.</span> </div>
                                          </div>
                                  </div>

                              </div>
                            </div>
                            </div>
                     </div>
                </div>
         </div>
       </div>
    </section><!-- End careers Section -->
</template>
 <script>
 
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

import emailjs from 'emailjs-com'
import { reactive} from 'vue'


const DUMMY_DATA = {
  educations: [
    {
      code: 'B.E',
      name: 'B.E',
    }, {
      code: 'B.Tech',
      name: 'B.Tech',
    },
    {
      code: 'Any PG courses in IT',
      name: 'Any PG courses in IT',
      
    }
  ],
  certificatesdata: [
    {
      code: 'Automation Testing',
      name: 'Automation Testing',
    }, {
      code: 'Java',
      name: 'Java',
    },
    {
      code: 'ROR',
      name: 'ROR',
      
    }
  ],
  passedoutdata: [
    {
      code: '2021',
      name: '2021',
    }, {
      code: '2020',
      name: '2020',
    },
    {
      code: '2017 - 2019 (Must Be Certificated)',
      name: '2017 - 2019 (Must Be Certificated)',
      
    }
  ],
                                                 
  MoreExperiences: [
    {
      code: 'ROR Developers',
      name: 'ROR Developers',
    },
     {
      code: 'Full stack developers',
      name: 'Full stack developers',
    },
    {
      code: '.Net Developers',
      name: '.Net Developers',
    },
     {
      code: 'MSBI Developers',
      name: 'MSBI Developers',
    },
    {
      code: 'Sharepoint Developers',
      name: 'Sharepoint Developers',
    }, {
      code: 'Scrum Masters',
      name: 'Scrum Masters',
    },
    {
      code: 'SDET Engineers',
      name: 'SDET Engineers',
    }, {
      code: 'Automation Test Engineers',
      name: 'Automation Test Engineers',
    },
    {
      code: 'ETL Testing /Big Data',
      name: 'ETL Testing /Big Data',
    }, 
    {
      code: 'SAP ( MM, PP, SD, FICPO)',
      name: 'SAP ( MM, PP, SD, FICPO)',
    }
  ],
     
}


export default defineComponent({
  setup() {
        const data = reactive({
          education: '',
          certificates:'',
          passedout: '',
        })

       
        return {
          data,
          ...DUMMY_DATA,
        }
      },

  components: {
      Carousel,
      Slide,
      Pagination,
      Navigation
    },

  settings: {
    snapAlign: 'center',
   },

   el: '#Markucicareerform',
  data() {
    return { 
     slider: [ 
               { id: 2, header: 'Full stack developers', },
               { id: 3, header: ' .Net Developers ', },
              { id: 4, header: 'MSBI Developers', },
               { id: 5, header: 'Sharepoint Developers', },
              { id: 6, header: 'Scrum Masters', },
               { id: 7, header: 'SDET Engineers', },
              { id: 8, header: 'Automation Test Engineers', },
               { id: 9, header: 'ETL Testing /Big Data', },
              { id: 10, header: 'SAP ( MM, PP, SD, FICPO)', },
              { id: 1, header: 'ROR Developers', }
   ],
          selected: '',
            email : "",
            emailBlured : false,
            emailfreshers : "",
            emailfreshersBlured : false,
            valid : false,
            username:"",
            usernameBlured:false,
            phone:"",
            phoneBlured:false,
            usernamefreshers:"",
            usernamefreshersBlured:false,
            phonefreshers:"",
            phonefreshersBlured:false,
            Successmessage: false
   }
   },
   methods:{
            validate : function(){
            this.emailBlured = true;
            this.usernameBlured = true;
            this.phoneBlured = true;
            
            this.emailfreshersBlured = true;
            this.usernamefreshersBlured = true;
            this.phonefreshersBlured = true;
            if( this.validEmail(this.email) && this.validPhone(this.phone) && this.validUsername(this.username)){
            this.valid = true;
            }
            
            if( this.validEmailfreshers(this.emailfreshers) && this.validPhonefreshers(this.phonefreshers) && this.validUsernamefreshers(this.usernamefreshers)){
            this.valid = true;
            }
            },

            validEmail : function(email) {
            var re = /(.+)@(.+){2,}\.(.+){2,}/;
            if(re.test(email.toLowerCase())){
            return true;
            }
            },

            validEmailfreshers : function(emailfreshers) {
            var re = /(.+)@(.+){2,}\.(.+){2,}/;
            if(re.test(emailfreshers.toLowerCase())){
            return true;
            }
            },

            validUsername : function(username) {
            if (/[a-zA-Z0-9]/.test(username)) {
            return true;
            }
            },
            
            validUsernamefreshers : function(usernamefreshers) {
            if (/[a-zA-Z0-9]/.test(usernamefreshers)) {
            return true;
            }
            },

            validPhone : function(phone) {
            if (phone.length > 9 && phone.length < 12 ) { return true; } }, 

            validPhonefreshers : function(phonefreshers) {
            if (phonefreshers.length > 9 && phonefreshers.length < 12 ) { return true; } }, 

              sendEmailFreshers(event) {
                const btn = document.getElementById('buttonFreshers');
                 event.preventDefault();
             this.validate();
             if(this.valid){
                this.Successmessage = true;
            emailjs.sendForm('service_Markuci', 'template_fresher',this.$refs.form, 'user_44TwNC7w6C35UtEBPAfaN')
              .then((result) => {
                btn.value = 'Send Email';
                    window.location.href = "https://forms.gle/YKz1BiKzBCooSY2n8"                    
                 console.log('SUCCESS!', result.status, result.text);
              }, (error) => {
                  console.log('FAILED...', error);
              });
             }
          },
          
          sendEmailExperience(event) {
                const btn = document.getElementById('buttonExperience');
                 event.preventDefault();
             this.validate();
             if(this.valid){
                this.Successmessage = true;
            emailjs.sendForm('service_Markuci', 'template_experience',this.$refs.form, 'user_44TwNC7w6C35UtEBPAfaN')
              .then((result) => {
                btn.value = 'Send Email';
                  window.location.reload()                   
                 console.log('SUCCESS!', result.status, result.text);
              }, (error) => {
                  console.log('FAILED...', error);
              });
             }
          },

         watch:{
              Successmessage(val){
                 if (val===true){
                    setTimeout(()=>this.Successmessage=false,8000);
                  }
                }
            }
            } 
  })
  </script>
  <style scoped>
        li.carousel__slide.carousel__slide--visible {
          width: 35% !important;
      }
  </style>
  <style>
 .form-control, .form-select {
    margin-top: 10px;
    }
      @media only screen and (min-width: 560px) and (max-width: 910px) {
   .modal-dialog {
    max-width: 700px !important;}        
        } 
         @media only screen and (min-width: 991px) and (max-width: 2561px) {
        .mobilefresher{
            display:none;
          }

        }
        @media only screen and (min-width: 0px) and (max-width: 990px) {
        .desktopfresher{
            display:none;
          }
        } 

      .formsuccess{
          color: #eb196f;
          font-size: 60px;
      }

      .popupsubmit:hover{
        background: #ffffff;
          border: 1px solid #eb196f;
          color: #eb196f;
          transition: .5s all;
          -webkit-transition: .5s all;
          -o-transition: .5s all;
          -ms-transition: .5s all;
          -moz-transition: .5s all;
      }
      .popupsubmit {
          color: #333;
          padding: 10px 25px;
          font-size: 16px;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 500;
          margin: 20px 0 0 0px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -o-border-radius: 5px;
          -ms-border-radius: 5px;
          border: 1px solid #333;
          background: none;
      }
      .form-label {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: 600;
      }
      .btn-close {
          z-index: 1;
      }

      .modal-content {
          border: none;
          position: relative;
          padding: 0 !important;
          font-size: 14px;
          border-radius: 0;
          -webkit-box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
          -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
          box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
      }
      .modal-content .modal-header {
          border: none;
          padding: 0;
      }
      .modal-content button.btn-close {
          position: absolute;
          top: 0;
          right: 0;
          padding: 0;
          margin: 0;
          width: 50px;
          height: 50px;
      }
      .modal-dialog {
          max-width: 900px;
      }
      .leftmodal{
          background: url(../assets/images/business.jpg) center center no-repeat;
          background-size: cover;
      }
      .leftmodal::before {
          content: "";
          position: absolute;
          background: rgba(245, 74, 154, 0.411);
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
      }
      .popuphead {
          color: #fff;
          z-index: 1;
          text-align: center;
      }
      .popuphead h2{
        font-size: 35px;
      }
      .popuphead p{
        font-size: 18px;
      }



  .Careerheading{
    text-align:left
  }
  @media (max-width: 767px) {
        .carousel__prev.navpage {
            top: 35%;
            left: 0px;
            opacity: 40%;
        }
        .carousel__next.navpage {
            top: 35%;
            right: 20px;
            opacity: 40%;
        }
        .carousel__prev.navpage:hover {
            opacity: 100%;
        }
        .carousel__next.navpage:hover {
            opacity: 100%;
        }
        }
        .carousel__prev.navpage:hover{
                background-color:#ed1d8d;
         }
         .carousel__next.navpage:hover{
                background-color:#ed1d8d;
            }

 .carousel__prev.navpage{
      box-sizing: content-box;
      top: 45%  !important;
      background-color: #72287a;
      left: -45px  !important;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  .carousel__next.navpage {
      box-sizing: content-box;
      background-color: #72287a;
      top: 45%;
      right: -10px !important;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
.careers .boxcareer {
  padding: 30px;
  margin: 10px;
  background: #fff;
  position: relative;
}
.careers h3 {
  font-family: "Poppins", sans-serif;
    color: #72287a;
  font-weight: 500;
    margin-bottom: 25px;
    font-size: 22px;
}
.careers ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}
.careers ul li {
  padding-bottom: 12px;
}
.careers .get-started-btn {
  background: #72287a;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 20px;
  color: #fff;
  border-color: #fff;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
  margin: 5px;
}
.box:hover .get-started-btn{
    background: #ed1d8d;
}
.box:hover {
    box-shadow: -10px 10px 76px rgb(54 127 255 / 12%)
}
.box:hover .carrerhead{
    color: #ed1d8d;
}
@media (max-width: 992px) {
  .careers .box {
    max-width: 60%;
  }
}
@media (max-width: 767px) {
  .careers .box {
    max-width: 80%;
  }
}
@media (max-width: 450px) {
  .careers .box {
    max-width: 100%;
  }
  .careers h3 {
    font-size: 22px !important;
}
.careers .get-started-btn {
    padding: 8px 13px;
    margin: 3px;
}
}
.careers {
    padding: 150px 30px 80px 30px;
}
</style>